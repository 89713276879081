.headerLogin {
    display: flex;
    background: #ffffff;
}

.containerLogin {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
}

.containerLeft {
    margin-top: 150px;
}

.containerRight {
    margin-top: 30px;
}

.menuLogin {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.logoLogin {
    border: none;
    background-color: transparent;
    margin-left: 80px;
    cursor: pointer;
}

.frase {
    width: 574px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    margin-bottom: 40px;
}

.isCount, .createCount {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
}

.entrar {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
}

.form {
    width: 370px;
    height: 62px;
    border-radius: 8px;
    background-color: #F0EFFF !important;
    font-family: 'Poppins';
    font-size: 13px;
    line-height: 22px;
    border: none;
}

::placeholder {
    color: #A7A3FF !important;
}

.btnEntrar {
    width: 370px;
    height: 59px;
    color: #ffffff;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0px 4px 61px 0px #4D47C366;
    background-color: #00D9FF;
    border: none;
}

.textForm {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}